<template>
  <div>
    <a-modal
      :visible="isShow"
      title="温馨提示"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="myDiv">
        <div>
          <p>
            <b>￥{{newMoney}}</b>
          </p>
           <a-result title="支付成功!" v-if="icon === 2">
            <template #icon>
              <a-icon
                type="smile"
                theme="outlined"
                :style="{ color: 'rgba(40, 172, 60, 1)' }"
              />
            </template>
          </a-result>
          <a-result title="支付失败!" v-if="icon === 3">
            <template #icon>
              <a-icon
                type="frown"
                theme="outlined"
                :style="{ color: 'rgba(40, 172, 60, 1)' }"
              />
            </template>
          </a-result>
          <div class="loading" v-if="icon === 1">
            <Loading />
          </div>  
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Loading from '@/components/payLoading/payLoading';
export default {
  props: ["isShow","newMoney",'icon'],
  components:{Loading},
  data() {
    return {
      timeouts:''
    };
  },
  watch:{
    icon:{
      handler(){
         if((this.icon===2 || this.icon===3) && this.isShow){
              clearTimeout(this.timeouts)
              this.timeouts = setTimeout(() => {
              this.$emit("choose", true);
              this.$emit("closeChong", true);
            }, 500)
           }
      },
      deep:true,
      },
      
    
  },
  methods: {
    handleCancel() {
      this.$emit('choose',true)
    },
    handleOk(e) {
      this.$emit('choose',true)
    },
  },
};
</script>
<style lang="less" scoped>
.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index:10;
}
/deep/.ant-modal{
 width: 50vh !important;
}
/deep/.ant-modal-content {
  background-color: #edeef2;
  width: 50vh;
  position:relative;
}
/deep/.ant-modal-header {
  background-color: #edeef2;
}
/deep/.ant-modal-body {
  .myDiv {
    height: 39vh;
    background: white;
    p {
      padding-top: 4vh;
      font-size: 5vh;
      text-align: center;
    }
    .footer {
      img {
        width: 4vh;
        margin-left: 17vh;
        margin-top: 8vh;
      }
      p {
        font-size: 2vh;
        margin-left: 3.5vh;
        margin-top: -3.5vh;
      }
    }
  }
}
/deep/.ant-btn {
  width: 16.9vh;
  height: 5.15vh;
  font-size: 1.8vh;
}
/deep/.ant-btn-primary {
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
  color: #fff;
  font-size: 1.8vh;
  padding: 0;
}
/deep/.ant-result{
  padding: 0;
  margin-bottom: -2vh;
}
/deep/.ant-modal-footer{
  text-align: center;
  display: none;
}
</style>
