<template>
  <div class="cz">
    <a-modal width="35vw"
      :visible="isShow"
      title="新增会员"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <div class="footerBtn">
          <a-button class="clearBtn" @click="handleCancel">关闭</a-button>
          <a-button class="OkBtn" @click="handleSubmit">确定</a-button>
        </div>
      </template>
      <a-form-model ref="ruleForm" :model="formData" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-button class="primaryone OkBtn">基本信息</a-button>
        <a-divider class="divider" />
        <a-form-model-item label="手机号码"  prop="mobile">
          <a-input v-model="formData.mobile" placeholder="请输入手机号码"/>
        </a-form-model-item>
        <a-form-model-item label="会员价"  prop="is_member">
          <a-radio-group v-model="formData.is_member">
            <a-radio :value="2">启用</a-radio>
            <a-radio :value="1">禁用</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="会员等级">
          <a-select v-model="formData.grade_id">
            <a-select-option   v-for="(item,index) in vipList" :key="index" :value="item.grade_id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="会员姓名">
          <a-input v-model="formData.nick_name" placeholder="请输入会员姓名" />
        </a-form-model-item>
        <a-form-model-item label="会员卡号">
          <a-input v-model="formData.card_number" placeholder="请输入会员卡号" />
        </a-form-model-item>
        <a-form-model-item label="会员生日">
          <a-date-picker v-model="formData.birthday" type="date" placeholder="请选择" valueFormat="YYYY-MM-DD"/>
        </a-form-model-item>
        <a-form-model-item class="label" label="会员性别">
          <a-select v-model="formData.gender" placeholder="请选择">
            <a-select-option value="0"> 未知</a-select-option>
            <a-select-option value="1"> 男 </a-select-option>
            <a-select-option value="2"> 女 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item class="label" label="店员" v-if="$store.state.shop_info.is_salesman == 2">
          <a-select v-model="formData.order_clerk_id" :allowClear="true" placeholder="请选择">
            <a-select-option v-for="guide in guides" :key="guide.clerk_id" :value="guide.clerk_id">
              {{ guide.real_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="会员年龄">
          <a-input v-model="formData.memberAge" placeholder="请输入会员年龄" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script >
import { addMember, getGuides , queryMembersgrade } from "@/request/mis";

export default {
  props: ["isShow"],
  data() {
    return {
      formData: {
        mobile: '',
        is_member: 1,
        grade_id: '',
        nick_name: '',
        card_number: '',
        // birthday: '',
        gender: '',
        order_clerk_id: '',
        memberAge: ''
      },
      guides: [],
      rules: {
        mobile:[{ required: true, message: '请输入手机号码'},{ pattern : /^1[3-9]{1}[0-9]{9}$/, message: '电话格式不正确'}],
        is_member:[{ required: true, message: '请选择是否启用会员价'}],
      },
      vipList:[],//会员等级列表
      
    };
  },
  mounted() {

  },
  watch:{
    isShow:{
      handler(){
        if(this.isShow){
           this.keyUp();
          getGuides().then((res) => {
            if(res.data.status===200){
                this.guides = res.data.data.list;
            }
           
          });
          queryMembersgrade ().then((res) => {
            if(res.data.status===200){
              this.vipList=res.data.data.list.data && res.data.data.list.data ;
            }     
          });
        }
      },
      deep:true,
    }
  },
  methods: {
     keyUp(){
        const _this=this;
        document.onkeydown = function(e) {
        let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
        if(e1 && e1.keyCode == 13){
          _this.handleSubmit();
        }
        }
      },
    handleCancel() {
      this.$emit('changeModal',false);
      this.formData={
        mobile: '',
        is_member: 1,
        grade_id: '',
        nick_name: '',
        card_number: '',
        gender: '',
        order_clerk_id: '',
        memberAge: ''
      }
    },
    handleSubmit(e) {
      const _this=this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          addMember(_this.formData).then((res) => {
            if(res.data.status===200){
                _this.$emit('chooseData',true);
                _this.$message.success('新增成功')
                _this.handleCancel()
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.cz {
  width: 5vw;
  height: 5vh;
  margin-top: -5vh;
}
/deep/.ant-calendar-picker {
  width: 100%;
}
/deep/.ant-form-item-label {
  margin-left: 10%;
  // padding: 0.5vh;
}
.ant-row {
  margin: 1vh;
}
/deep/.ant-btn {
  width: 16.9vh;
  height: 5.15vh;
  font-size: 1.8vh;
}
/deep/.ant-modal-content {
  position: relative;
  background:rgba(237, 238, 242, 1);
  background-clip: padding-box;
  border: 0;
  // margin-top: -5vh;
  pointer-events: auto;
}
.divider {
  margin-top: 1vh;
}
.primaryone {
  padding: 0;
  width: 9.5vh;
  height: 4.3vh;
  margin: -1vh;
  font-size: 1.8vh;
  border-radius: 0.5vh 0.5vh 0 0;
}
/deep/.ant-modal-body {
  padding: 2.6vh;
}
/deep/.ant-form-item label{
  font-size: 1.8vh;
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
.footerBtn{
  display: flex;
  justify-content: space-around;
  padding: 0 2vw;
}
/deep/.ant-select-selection__rendered{
   font-size: 1.8vh;
}
/deep/.ant-input{
  height:3.9vh;
}
</style>