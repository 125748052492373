<template>
  <div>
    <a-modal
      :visible="isShow"
      title="请扫码"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="myDiv" @mousedown="stopClick">
        <div>
          <p>
            <b>￥{{ newMoney }}</b>
          </p>
          <div class="input">
            <a-form-model :model="form" :rules="rules" ref="ruleForm">
              <a-form-model-item ref="price" prop="price" label="">
                <a-input
                  class="input"
                  v-model="auth_no"
                  ref="input"
                ></a-input>
              </a-form-model-item>
            </a-form-model>
          </div>
           <img src="@/assets/sao.png" alt="" v-if="icon === 0">
          <a-result title="支付成功!" v-if="icon === 2" class="loading" >
            <template #icon>
              <a-icon
                type="smile"
                theme="outlined"
                :style="{ color: 'rgba(40, 172, 60, 1)' }"
              />
            </template>
          </a-result>
          <a-result title="支付失败!" v-if="icon === 3" class="loading" >
            <template #icon>
              <a-icon
                type="frown"
                theme="outlined"
                :style="{ color: 'rgba(40, 172, 60, 1)' }"
              />
            </template>
          </a-result>
           <div class="loading" v-if="icon === 1">
            <Loading />
          </div> 
           <!-- <audio ref="audio" :src="speech_path" autoplay="autoplay"></audio> -->
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Loading from '@/components/payLoading/payLoading';
export default {
  props: ["isShow", "newMoney",'icon','speech_path','data_state'],
  components:{Loading},
  data() {
    return {
      form: {
        auth_no: "",
      },
      auth_no:'',
      rules: {
        //付款码
        auth_no: [
          { required: true, trigger: "blur", message: "请输大于0的数字" },
        ],
      },
      time: "", //接收定时器
      timeouts:'',
      canPay:true,
      oldValue:''
    };
  },
   watch: {
    auth_no(val){
      if(val){
          this.canPayHandle(val)
      }
    },
    // form: {
    //   handler() {
    //     clearTimeout(this.timeout);
    //     this.timeout = setTimeout(() => {
    //       if(this.form.auth_no !=='' && this.form.auth_no.length==18){
    //            this.$parent.rechargeRequest(this.form.auth_no)
    //       }else if(this.form.auth_no !=='' && this.form.auth_no.length>18){
    //         this.$message.warn('请勿重复扫码!',1.5);
    //         this.form.auth_no =''
    //       } 
    //     }, 10);
    //   },
    //   deep: true,
    // },
     data_state:{
      handler(){
        const _this=this;    
            if(_this.data_state === 1){
              this.time=setInterval(() => {
                  setTimeout( this.$parent.rechargeRetRequest(), 0)           
              }, 1000)
           }else{
              clearInterval(this.time);
           };
          if(this.data_state===2 ){
              clearTimeout(this.timeouts)
              this.timeouts = setTimeout(() => {
              _this.$emit("choose", true);
              _this.$emit("closeChong", true);
                _this.auth_no="";
            }, 500)
            if(this.speech_path){
              bound.play(this.speech_path)
              //   this.$nextTick(() => {
              //   _this.$refs.audio.play()
              // })
            }
            
           }; 
            if( this.data_state===3){
              clearTimeout(this.timeouts)
              this.timeouts = setTimeout(() => {
              _this.$emit("choose", true);
              _this.$emit("closeChong", true);
                _this.auth_no="";
            }, 500)
           };   
      },
      deep:true
    },
    isShow:{
      handler(){
        this.$nextTick(() => {
       this.$refs.input.focus();
      })
      },
      deep:true,
    }
  },
  updated() {
  },
  methods: {
    canPayHandle(value){
        if(this.canPay){
            if(this.oldValue == value){
                this.canPay = false
                this.$parent.rechargeRequest(this.auth_no)
                setTimeout(() => {
                    this.canPay = true
                },200)
            }else{
                setTimeout(() => {
                    this.oldValue = value
                    this.canPayHandle()
                },100)
            }
        }
    },
     stopClick(event){
      event.preventDefault()
    },
    handleOk() {
       if(this.icon !== 1){
          this.$emit("choose", true); //关闭按钮
          this.auth_no=""
       }
    },
    handleCancel() {
      if(this.icon !== 1){
          this.$emit("choose", true); //关闭按钮
          this.auth_no=""
       }
    },
  },
 
};
</script>
<style lang="less" scoped>
.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index:10;
}
/deep/.ant-modal{
 width: 50vh !important;
}
/deep/.ant-modal-content {
  background-color: #edeef2;
  width: 50vh;
  position:relative;
}
/deep/.ant-modal-header {
  background-color: #edeef2;
}
/deep/.ant-modal-body {
  height:50vh;
  .myDiv {
    background: white;
    padding-bottom: 4vh;
    height:38vh;
    p {
      padding-top:3vh;
      font-size: 5vh;
      text-align: center;
    }
    .myImg {
      text-align: center;
      img {
        width: 12vh;
      }
    }
    .footer {
      .wechat {
        img {
          width: 4vh;
          margin-top: 8vh;
          margin-left: 3.5vh;
        }
        p {
          font-size: 2vh;
          margin-top: -3.5vh;
          margin-left: -23vh;
        }
      }
      .alipay {
        img {
          width: 4vh;
          margin-top: -10vh;
          margin-left: 29vh;
        }
        p {
          font-size: 2vh;
          margin-left: 30vh;
          margin-top: -7.5vh;
        }
      }
    }
  }
}
.input {
  padding:0 1vw;
  color:black;
}
/deep/.ant-input {
  height: 2vh;
  width:10vw;
  position: absolute;
  left:1vw;
  top:10vh;
  z-index: 1;
  border-color: white;
  background-color:rgba(237, 238, 242, 1) ;
  opacity: 0;
}
/deep/.ant-btn {
  width: 16.9vh;
  height: 5.15vh;
  font-size: 1.8vh;
}
/deep/.ant-btn-primary {
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
  color: #fff;
  font-size: 1.8vh;
  padding: 0;
}
/deep/.ant-modal-footer{
  text-align: center;
  display: none;
}
/deep/.ant-result{
padding:0;
}
/deep/.ant-result-icon{
  margin-bottom: 0;
}
img{
  position: relative;
  left:2vw;
  top:-3vh;
  height: 20vh;
  z-index: 2;
  width:40vh;
}

</style>
