<template>
  <div class="Div">
    <a-modal
      :visible="isShow"
      @cancel="handleCancel"
      :title="title"
    >
      <template slot="footer">
        <div class="footerBtn">
          <a-button class="clearBtn" @click="handleCancel">关闭</a-button>
          <a-button class="OkBtn" @click="handleSubmit">充值</a-button>
        </div>
      </template>
      <!-- <div class="title">会员查询</div>
      <div class="title">会员充值</div> -->
      <div class="search" v-if="functionJump===true">
        <div>会员卡号/手机号：</div>
        <div class="searchInput">
          <a-input-search placeholder="输入手机号/卡号"  @search="searchButton"  v-model="card"  enter-button="查询"/>
           <!-- <a-input-search placeholder="输入手机号/卡号" v-model="card"/> -->
        </div>
        <!-- <a-button type="primary" @click="searchButton" > 查询</a-button> -->
      </div>
      <div class="mySpan" style="height: 8vh" v-if="functionJump===true">
        <h3 style="font-size: 2vh; margin-left: 2vh">
          <b>会员 : {{(detail&&detail.nick_name)}}</b>
        </h3>
        <p style="font-size: 1.8vh; margin-left: 2vh">
          手机号: {{ (detail&&detail.mobile)}}
        </p>
        <p class="Span" style="font-size: 1.8vh">
          卡号:{{ (detail&&detail.card_number)}}
        </p>
        <p class="ye" style="font-size: 1.8vh">
          <b>余额:{{(detail&&detail.balance)}}</b>
        </p>
      </div>
        <div class="mySpan" style="height: 8vh"  v-if="functionJump===undefined">
        <h3 style="font-size: 2vh; margin-left: 2vh">
          <b>会员 : {{  (fData&&fData.nick_name)}}</b>
        </h3>
        <p style="font-size: 1.8vh; margin-left: 2vh">
          手机号: {{  (fData&&fData.mobile)}}
        </p>
        <p class="Span" style="font-size: 1.8vh">
          卡号:{{  (fData&&fData.card_number)}}
        </p>
        <p class="ye" style="font-size: 1.8vh">
          <b>余额:{{  (fData&&fData.balance)}}</b>
        </p>
      </div>
      <a-divider style="margin: 0" />
      <a-form
        :form="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 10 }"
        @submit="handleSubmit"
      >
        <a-form-item class="labelone" style="margin-left: -2vh" label="店员"  v-if="$store.state.shop_info.is_salesman == 2">
          <a-select
            v-decorator="['order_clerk_id']"
            placeholder="请选择店员"
            @change="handleSelectChange"
          >
            <a-select-option
              v-for="guide in guides"
              :key="guide.clerk_id"
              :value="guide.clerk_id"
            >
              {{ guide.real_name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="付款方式">
          <a-radio-group
            @change="handleSelectPayTypeChange"
             v-decorator="[
              'pay_type',
              {
                initialValue: 'aggregatepay',
                rules: [{ required: true, message: '请选择付款方式' }],
              },
            ]"

          >
            <a-radio style="radioStyle;margin-left: 1.5vh" value="aggregatepay">
              扫码支付
            </a-radio>
            <a-radio style="radioStyle" value="cash"> 现金支付 </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-tabs type="card" @change="callback" ref="ruleForm" default-active-key="1" v-model="activeKey">
          <a-tab-pane key="1" tab="充值套餐" >
            <div>
              <ul class="ulJian">
                <li
                  v-for="(item, index) in items"
                  :key="index"
                  @click="clickCategory(item,index)"
                  :class="[categoryIndex === index ? 'active' : '']"
                >
                  <div>
                    {{ item.plan_name }}
                  </div>
                  <div>
                    <p class="myp">
                      {{ "充" + item.money }}
                      {{ "赠" + item.gift_money }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="自定义金额" v-if="this.is_custom==1"
>
            <div class="money">
        <a-form-model 
          :model="forms" 
          ref="ruleForm"
          :label-col="{ span: 4}"
          :wrapper-col="{ span: 10 }"
        >
          <a-form-model-item class="myLabel" ref="price" prop="price"  label="充值金额">
            <a-input
              class="input"
              v-model="forms.money"
              ref="input" 
              placeholder="输入充值金额"
              prefix="￥"
              suffix="RMB"
            ></a-input>
          </a-form-model-item>          
      </a-form-model>
            </div>
            <div class="myP">
              <p>手动输入大于0的充值金额</p>
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-form>
    </a-modal>
    <cashRecharge
      :isShow="showCash"
      :newMoney="money"
      @choose="changeList"
      :icon="icon"
      @closeChong="closeUp"
    />
    <Scan
      :isShow="showWechat"
      :newMoney="money"
      @choose="changeCancel"
      :icon="icon"
      @closeChong="closeUp"
      :speech_path="speech_path"
      :data_state='data_state'

    />
  </div>
</template>
<script >
import { getGuides, queryRechargePackages, recharge,queryRechargeResult,search,rechargeSetting} from "@/request/mis";

import cashRecharge from "./cashRecharge";
import Scan from "./Scan";
export default {
  props: ["fData",'isShow','functionJump'],
   components: {
    cashRecharge,
    Scan,
  },
  data() {
    return {
      showCash: false,//现金支付
      showWechat: false,
      queryRechargePackages: [],
      items: [],//充值方式数组
      guides: [],//店员数组
      money: '',//充值的金额
      // areShow: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      categoryIndex: 0, //点击当前背景变成白色索引
      key: 20, //充值方式
      paytype: "", //付款方式
      plan_id:'',//选取套餐id
      order_clerk_id:'',//店员id
      forms:{
        money:''
      },
      icon:0,//支付状态
      recharge_id:'',//请求会员充值接口返回
      card:'',//查询会员
      detail:'',//查询到的会员信息
      title:'会员充值',
      activeKey:'1',
      speech_path:'',
      is_custom:0,
      data_state:0,//是否继续请求支付状态接口
    };
  },
   watch:{
    isShow:{
      handler(){
        if(this.isShow){
          if(this.functionJump===undefined){
            this.keyUp()
          }
          getGuides().then((res) => {
            if(res.data.status===200){
                this.guides = res.data.data.list;
            }
          });
          queryRechargePackages().then((res) => {
            if(res.data.status===200){
               this.items = res.data.data.list;
            }        
          rechargeSetting ({}).then((res) => {
            if(res.data.status===200){
              this.is_custom=res.data.data.is_custom;
              console.log(this.is_custom)
            }     
          });
        });     
        }
      },
      deep:true,
    },
    items:{
      handler(){
        if(this.key = 20){
         this.money=this.items && this.items[0] && this.items[0].money;
          this.plan_id=this.items && this.items[0] && this.items[0].plan_id;
        }
      },
      deep:true,
    },
    forms:{
      handler(){
        this.$set(this.forms , 'money' , this.getTofixed(this.forms.money))
        if(this.key===10){
          this.money=this.forms.money;
        }
      },
      deep:true,
    },
    functionJump:{
       handler(){
        if(this.functionJump===true){
          this.title="会员查询"
        }else{
        this.title="会员充值"
        }
      },
      deep:true,
    }
  },
   updated(){
    //  console.log(this.is_customs)
        
    },
  mounted() {
     if(this.functionJump===true){
          this.title="会员查询"
        }
   
  },
  methods: {
    clickCategory(item,index) {
      this.categoryIndex = index;
      this.money=item && item.money;
      this.plan_id=item && item.plan_id;
    },
    keyUp(){
        const _this=this;
        document.onkeydown = function(e) {
          let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
          if (e1 && e1.keyCode == 13) { //点击F6先送
             _this.handleSubmit()
           } 
          }

    },
    changeCancel(number) {
      //关闭提示modal
      this.showWechat = false;
      this.icon=0;
    },
    changeList() {
      //关闭提示modal
      this.showCash = false;
      this.icon=0;   
    },
    closeUp(){ //付款成功后关闭充值modal
      this.detail="";
      this.card="";
      this.forms.money='';
      this.$emit('changeModal',false)
      this.data_state = 0
    },
    handleCancel() {
      // this.areShow = false;
      this.$emit('changeModal',false)
      this.form.resetFields();
      this.forms.money="";
      this.activeKey='1';
      this.detail="";
      this.card="";
      // this.functionJump=undefined
      
    },
    callback(key) { //充值套餐tabs
    this.activeKey=key;
      if (key == 1 ) {
        this.key = 20;
        if(this.pay_type==='aggregatepay' ){
          this.money=this.items && this.items[this.categoryIndex].money;
        }else if(this.pay_type==='cash'){
           this.money=this.items && this.items[this.categoryIndex].money;
        }  
      }
      if (key == 2) {
        this.key = 10;
          if(this.pay_type==='aggregatepay' ){
        
            this.money=this.forms.money;
        }else if(this.pay_type==='cash'){
           this.money=this.forms.money;
        }  
      }
    },
    handleSubmit(e) {
      const _this=this;
      this.form.validateFields((err, values) => {
        if (!err) {
           _this.pay_type=values && values.pay_type;
           if( !this.detail.user_id && this.functionJump===true){
             this.$message.warn('输入手机号/卡号/姓名 查询会员卡')
           }else{
               if( _this.pay_type==='cash'&& _this.key===20){
               _this.rechargeRequest();
                }else if(_this.pay_type==='aggregatepay' && _this.key===20){
                    _this.showWechat=true;
                }else if(_this.key===10 && _this.forms.money===""){
                    _this.$message.warn('请输入充值金额',1.5);
                }else if(_this.key===10 && _this.forms.money!==""){
                  if(_this.pay_type==='cash'){
                      _this.rechargeRequest();
                  }else if(_this.pay_type==='aggregatepay' && _this.key===10){
                      _this.showWechat=true;
                  }
               }  
           }
         
        }
          
      });
     
    },

    handleSelectPayTypeChange(value) {
    },
    handleSelectChange(value) {
      this.order_clerk_id=value;
    },
    rechargeRequest(auth_no){ //请求会员充值
    const _this=this;
      const message={
            userId:(this.fData&& this.fData.user_id)|| (this.detail&& this.detail.user_id) ,//充值会员ID
            target: "balance",//写死balance
            form: {
              balance: {
                mode: "inc",//写死inc
                money: this.money,//充值的金额
                remark: "",//备注
                recharge_type: this.key,//充值方式(10自定义金额 20套餐充值)
                plan_id: this.plan_id,//使用的套餐ID，recharge_type为20时必填
                pay_type: this.pay_type,//支付类型：alipay（支付宝）wechat（微信）cash（现金）
                auth_no: auth_no,//付款码，微信支付宝时必填
                promoter_clerk_id: this.order_clerk_id,//推广员ID，数据通过推广员列表接口获得
             }
          }
        }
      recharge(message).then((res) => {
        if(res.data.status===200){
          const recharge_id= res.data.data.recharge_id && res.data.data.recharge_id;
          _this.recharge_id=recharge_id;
          _this.rechargeRetRequest()
        }
      });
    },
    rechargeRetRequest(){  //请求查询会员充值结果接口 
      const _this=this;
      queryRechargeResult({recharge_id:this.recharge_id}).then((res) => {
        if(res.data.status===200){
          // const recharge_id= res.data.data.recharge_id && res.data.data.recharge_id;
          const status=res.data.data.status && res.data.data.status;
           _this.data_state=res.data.data.data_state && res.data.data.data_state;
          console.log(status)
          _this.speech_path= res.data.data.speech_path && res.data.data.speech_path;
          _this.icon=status;
          if(status===2){
            this.form.validateFields((err, values) => {
             if(values.pay_type==='cash'){
                 _this.showCash=true;
             }
            })
            _this.$emit('updateList',true);
            _this.form.resetFields(); //清除书写框
            _this.activeKey='1';
          }
        }
      });
    },
    searchButton(){
      if(this.card==""){
        this.$message.warn('请输入手机号/卡号')
      }else{
         search({keywords:this.card}).then((res) => {
            if(res.data.status===200){
              const detail=res.data.data&& res.data.data;
              this.detail=detail;
            }        
            // this.queryRechargePackages = res.data.data.list;
        });

      }
      
    }

  },
 
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-content {
  width: 80vh;
  background:rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body {
  padding: 1vh;
}
/deep/.ant-modal{
 width: 80vh !important;
}

.Span {
  margin-top: -4.5vh;
  margin-left: 23vh;
}
.ulJian {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  .active {
    background: #1890ff;
    color: #ffffff;
    border-color: #1890ff;
  }
  li {
    width: 20vh;
    height: 7vh;
    margin: 0.7vw;
    background-color: rgba(249, 249, 249, 1);
    font-weight: bold;
    text-align: center;
    margin-left: 4vh;
    line-height: 4.5vh;
    border: 0.1vw solid #b5b5b6;
    border-radius: 4px;
    cursor: pointer;
  }
  li:hover {
    // background: #1890ff;
    // color: #ffffff;
  }
}

.ye {
  margin-top: -4.7vh;
  margin-left: 48vh;
  color: red;
}
.myp {
  font-size: 1.4vh;
  line-height: 0;
  text-align: center;
  color: rgba(175, 192, 210, 1);
}
/deep/.ant-input {
  height: 4vh;
  width: 50vh;
}
.ant-form-item {
  margin-bottom: 2.5vh;
}
/deep/.ant-btn {
  width: 14vh;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-tabs-nav-scroll {
  text-align: center;
}

.myP {
  color: rgba(175, 192, 210, 1);
  P {
    margin-left: 12.5vh;
    margin-top: -1.5vh;
  }
}
.ant-form {
  margin-top: 2vh;
}
.Recharge {
  width: 5vw;
  height: 3.6vh;
  padding: 0vh 0vh;
  color: white;
  cursor: pointer;
  border-radius: 0.5vh;
  background: #3293ff;
  font-size: 1.6vh;
  border: 0;
}
.Recharge:hover {
  background-color: #15a3e6;
}
/deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border-radius: 0.3vh 0.3vh 0.3vh 0.3vh;
  border: 1px solid #1890ff;
  margin: 0vh;
  width: 35vh;
  line-height: 3.6vh;
  height: 3.6vh;
  color: #1890ff;
  font-size: 1.8vh;
}
/deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: #1890ff;
  border-radius: 0.3vh 0.3vh 0.3vh 0.3vh;
  color: white;
}
/deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 4.1vh;
}

/deep/form .ant-select,
form .ant-cascader-picker {
  width: 15vw;
}

/deep/.ant-modal-footer {
  text-align: center;
}

// /deep/.ant-modal-footer button + button {
//   width: 14vh;
//   height: 5vh;
//   font-size: 1.8vh;
//   border-radius: 0.5vh;
//   color: white;
//   margin-left: 4vh;
//   background: rgb(238, 121, 12);
//   border-color: rgb(238, 121, 12);
// }
/deep/.ant-input-affix-wrapper .ant-input-suffix {
  right:-130px;
}
.search{
  display: flex;
  font-size: 1.8vh;
  line-height: 5vh;
}
.searchInput{
/deep/ .ant-input{
    width:18vw;
    height:5vh;
  }
}
.search{
  margin-left: 2vh;
 /deep/ .ant-btn {
    width: 6vw;
    height: 5vh;
    font-size: 1.8vh;
    // margin-left:1.5vw;
    background: rgb(238, 121, 12);
     border-color: rgb(238, 121, 12);
     text-align: center;
  }
}
.title{
  font-size: 1.8vh;
  margin-left:2vh;
}
/deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container{
  height:40px;
  border-bottom:1px solid rgba(237, 238, 242, 1);
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
   font-size: 1.8vh;
}
/deep/.ant-form-item label{
  font-size: 1.8vh !important;
}
.active .myp{
  color:white;
}
 /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:nth-child(1) {
   border-radius: 3px 0px 0px 3px;
}
/deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:nth-child(2) {
   border-radius: 0px 3px 3px 0px;
}
.footerBtn{
  text-align: center;
  .clearBtn{
    margin-right: 6vw;
  }
}
/deep/.ant-select-selection__rendered{
  font-size: 1.8vh;
  height:3.5vh;
  line-height: 3.5vh;
}
/deep/.ant-select-selection--single{
  height:3.5vh !important;
  line-height: 3.5vh;
}
</style>