<template>
  <div class="loadings">
    <div class="content">
      <img src="../../assets/loading.png" alt="">
  
    </div>
    <div class="font">支付中请稍后...</div>
    
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      
    };
  },
  created() {},
  computed: {
   
  },
  methods: {
   
   
  },
  mounted() {
    
  },
  updated() {},
};
</script>
<style lang="less" scoped>
.loadings{
    border-radius: 4px; 
    z-index:10;
     width:10vw;
    height:10vw;
    background: rgba(0, 0, 0, 0.4214285714285715);
   .font{
      text-align: center;
      line-height: 7vh;
      color:white;
    }   
  .content{ 
    margin:0 auto;
    display: flex;
    align-items: center;   
    justify-content: center;
    img{
      margin-top:2vh;
      width:5vw;
      height:5vw;     
    }
    
  }

}



</style>
